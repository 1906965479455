.gamePageAddScoreTxt{
    position: fixed;
    left:50%;
    top:55%;
    transform: translate3d(-50%,0,0);
    z-index: 999;
    font-size: 40px;
    color: #8CFFF8;
    font-weight: 600;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    animation: gamePageAddScoreTxtAni 1s;
}

@keyframes gamePageAddScoreTxtAni
{
    from {top: 55%;font-size: 40px;opacity: 1;}
    to {top: 35%;font-size: 30px;opacity: 0.6;}
}
 
@-webkit-keyframes gamePageAddScoreTxtAni /* Safari 与 Chrome */
{
    from {top: 55%;font-size: 40px;opacity: 1;}
    to {top: 35%;font-size: 30px;opacity: 0;}
}
.video-box {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}

.video-box video {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}